import { classNames } from "@/utils";
import { Switch } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export default function ContactForm(props) {
  const FORMSPARK_URL = "https://submit-form.com/Q2WUwwVk";

  const { locale } = useLocalization();
  const { t } = useTranslation("common");
  const [agreed, setAgreed] = useState(false);

  const localePrefix = locale !== "pl" ? locale + "/" : "";
  const callbackUrl = `https://yespeople.pl/${localePrefix}wyslano`;

  return (
    <form
      action={FORMSPARK_URL}
      method="POST"
      className={classNames(
        props.className,
        "grid grid-cols-1 gap-y-6 max-w-3xl mx-auto text-left"
      )}
    >
      <div>
        <label htmlFor="name" className="sr-only">
          {t("contactForm.name.label")}
        </label>
        <input
          type="text"
          name="name"
          id="name"
          autoComplete="name"
          className="block px-4 py-3 w-full placeholder-gray-500 rounded-md border-gray-300 shadow-sm"
          placeholder={t("contactForm.name.placeholder")}
          required
        />
      </div>

      <div className="hidden">
        <label htmlFor="company" className="sr-only">
          {t("contactForm.company.label")}
        </label>
        <input
          id="company"
          name="company"
          type="text"
          autoComplete="email"
          className="block px-4 py-3 w-full placeholder-gray-500 rounded-md border-gray-300 shadow-sm"
          placeholder={t("contactForm.company.placeholder")}
        />
      </div>

      <div>
        <label htmlFor="phone" className="sr-only">
          {t("contactForm.phone.label")}
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          autoComplete="tel"
          className="block px-4 py-3 w-full placeholder-gray-500 rounded-md border-gray-300 shadow-sm"
          placeholder={t("contactForm.phone.placeholder")}
          required
        />
      </div>

      <div>
        <label htmlFor="email" className="sr-only">
          {t("contactForm.email.label")}
        </label>
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          className="block px-4 py-3 w-full placeholder-gray-500 rounded-md border-gray-300 shadow-sm"
          placeholder={t("contactForm.email.placeholder")}
        />
      </div>

      <div>
        <label htmlFor="message" className="sr-only">
          {t("contactForm.message.label")}
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          className="block px-4 py-3 w-full placeholder-gray-500 rounded-md border border-gray-300 shadow-sm"
          placeholder={t("contactForm.message.placeholder")}
          defaultValue={""}
        />
      </div>

      <div>
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <Switch
              checked={agreed}
              onChange={setAgreed}
              className={classNames(
                agreed ? "bg-green-600" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
              )}
            >
              <span className="sr-only">{t("contactForm.agreed.label")}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  agreed ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
          </div>
          <div className="ml-3">
            <p className="text-base text-gray-500">
              <Trans i18nKey="contactForm.agreed.text">
                <Link
                  to="/polityka-prywatnosci"
                  className="font-medium text-gray-700 underline"
                ></Link>
              </Trans>
            </p>
          </div>
        </div>
      </div>

      <input type="hidden" name="_redirect" value={callbackUrl} />
      <input
        type="hidden"
        name="_email.subject"
        value="Nowa wiadomość z formularza"
      />
      <input type="hidden" name="_email.from" value="Yes!People" />

      <button
        disabled={!agreed}
        type="submit"
        className="flex justify-center items-center w-full px-8 md:px-10 py-3 md:py-4 text-base md:text-lg font-medium text-white bg-green-600 hover:bg-green-700 disabled:bg-green-600 rounded-md border border-transparent shadow disabled:opacity-50 "
      >
        {t("contactForm.submit.label")}
        <ArrowRightIcon className="ml-3 -mr-1 h-6 w-6" aria-hidden="true" />
      </button>
    </form>
  );
}
