import { classNames } from "@/utils";
import React from "react";

export default function SectionCenter(props) {
  return (
    <div
      className={classNames(
        "max-w-7xl mx-auto mt-10 sm:mt-16 px-4 sm:text-center",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
