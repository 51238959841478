import ContactForm from "@/components/common/ContactForm";
import Layout from "@/components/layout/Layout";
import SectionCenter from "@/components/layout/SectionCenter";
import SectionTitle from "@/components/layout/SectionTitle";
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation("common");

  return (
    <Layout>
      <GatsbySeo
        title={t("contact.seo.title")}
        description={t("contact.seo.description")}
      />
      <SectionTitle title={t("contact.title")} subtitle={t("contact.subtitle")}>
        {t("contact.description")}
      </SectionTitle>
      <SectionCenter>
        <ContactForm />
      </SectionCenter>
    </Layout>
  );
}
